<template>
	<div class="open-account-type">
		<h4 class="h4_title">账户类型</h4>

		<!--
		<div class="item" @click="toBank(1)">
			<div class="image"><img :src="resourceURL + 'images/bank/icon_bank_1.png'" alt=""></div>
			<div class="info">国内银行账户</div>
		</div>
		-->
		
		<div class="item" @click="toBank(2)">
			<div class="image"><img :src="resourceURL + 'images/bank/icon_bank_2.png'" alt=""></div>
			<div class="info">海外银行账户</div>
		</div>

		<div class="item" @click="toBank(3)" v-if="!customer_bank">
			<div class="image"><img :src="resourceURL + 'images/bank/icon_bank_3.png'" alt=""></div>
			<div class="info">USDT</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'withdraw_banks',
		data() {
			return {
				token: "",
				customer_bank: window.sessionStorage.getItem('customer_bank'),
				resourceURL: this.$axios.defaults.resourceURL,
			}
		},
		mounted() {
			if (this.$route.query.token == "" || this.$route.query.token == undefined) {
				this.$router.replace('/nologin')
			} else {
				this.token = this.$route.query.token
			}
		},
		methods: {
			toBank(type) {
				window.sessionStorage.setItem('bank_type', type)

				this.$router.push('/withdraw/bank?token=' + this.token)
			}
		},
	}
</script>
